import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	StructureAPI,
	IStructureAPI,
	IPropsStore,
	Props,
} from '@wix/thunderbolt-symbols'
import { SospPageConfig } from './types'
import { name } from './symbols'

const sospFactory = (
	structureApi: IStructureAPI,
	propsStore: IPropsStore,
	pageFeatureConfig: SospPageConfig
): IPageWillMountHandler => {
	return {
		async pageWillMount(pageId) {
			const { sospCompId, sospParentId, position, pagesToShowSosp } = pageFeatureConfig
			const { components } = structureApi.get(sospParentId)
			if (pagesToShowSosp[pageId]) {
				if (!components.includes(sospCompId)) {
					structureApi.addComponentToParent(sospParentId, sospCompId, position)
				}
				propsStore.update({ [sospParentId]: { className: 'page-with-sosp' } })
			} else {
				if (components.includes(sospCompId)) {
					structureApi.removeComponentFromParent(sospParentId, sospCompId)
				}
				propsStore.update({ [sospParentId]: { className: 'page-without-sosp' } })
			}
		},
	}
}

export const Sosp = withDependencies([StructureAPI, Props, named(PageFeatureConfigSymbol, name)], sospFactory)
